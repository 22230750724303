import { Breadcrumbs, Button, Card, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormikTextField, Layout, FormikSelect } from '../../components';
import { useNavigate } from 'react-router-dom';
import { SiteMap } from '../../Routes';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import FormikForm from '../../components/FormikForm/FormikForm';
import * as Yup from "yup";
import { Regx } from '../../utils/Regx';
import { Axios, TCCREATE_URL } from '../../utils/apiUtils';
import { bindActionCreators } from 'redux';
import { actions } from '../../redux';
import { useDispatch, useSelector } from 'react-redux';
import { TCStatus } from '../../utils/constants';

function TelleCallersCreatePage() {
  const authState = useSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  const Validator = Yup.object().shape({
    name: Yup.string()
      .required("Username Required.")
      .max(60, "Username exceeds Character limit.")
      .min(6, "Username too Short.")
      .matches(Regx.plainText, "Invalid Username."),
    password: Yup.string()
      .required("Password Required.")
      .min(6, "Password too Short."),
    mobile: Yup.string()
      .min(10, '10 digit Mobile Number Required.')
      .max(10, '10 digit Mobile Number Required.'),
    email: Yup.string().email('Invalid Email Address.')
  });

  const handleSubmit = async (data) => {
    await callTCCreateAPI(data);
  }

  const callTCCreateAPI = async (inputPayload) => {
    Axios.post(TCCREATE_URL, inputPayload).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
        navigate(SiteMap.TelleCallersPage.path);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  return (
    <Layout >
      <div className='aj-main'>
        <Breadcrumbs aria-label="breadcrumb" className='aj-bread-crumbs'>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.DashboardPage.path)}}>
            Dashboard
          </Link>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.TelleCallersPage.path)}}>
            TeleCallers
          </Link>
          <Typography color="text.primary">Create New Member</Typography>
        </Breadcrumbs>

        <Card sx={{ minWidth: 180}} className="aj-card-main">
          <div className="aj-card-heading back-primary">
            <h4>Create New Member</h4>
          </div>
          <div className="aj-card-body">
            <FormikForm validator={Validator} initialValues={{'hr_id': authState.user.id}} onSubmit={handleSubmit}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 0.5, sm:0.5, md: 1, lg: 1, xl:2 }}>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikTextField 
                      label="Username"
                      name="name"
                      className="aj-text-input"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikTextField 
                      label="Mobile Number"
                      name="mobile"
                      className="aj-text-input"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikTextField 
                      label="Email Address"
                      name="email"
                      className="aj-text-input"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikTextField 
                      label="Password"
                      name="password"
                      className="aj-text-input"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikTextField 
                      label="Monthly Target Amount"
                      name="monthly_tar_amount"
                      className="aj-text-input"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikTextField 
                      label="Daily Target Leads"
                      name="daily_tar_leads"
                      className="aj-text-input"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikSelect
                      label="Status"
                      size="small"
                      name="status"
                      items={TCStatus}
                      defaultValue='Active'
                      placeholder="Choose Status"
                      />
                      
                  </Grid>
                </Grid>
                <br />
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" className="aj-card-footer">
                  <Button variant="contained" color="primary" className="aj-btn-bold"  onClick={() => {navigate(SiteMap.TelleCallersPage.path)}}>
                    Back
                  </Button>
                  <Button variant="contained" type="submit" className='aj-btn-primary' >
                    Create
                  </Button>
                </Stack>
              </Box>
            </FormikForm>
          </div>
        </Card>
      </div>
    </Layout>
  )
}

export default TelleCallersCreatePage