import { Box, Breadcrumbs, Button, Card, Grid, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { FormikSelect, FormikTextField, Layout } from '../../components';
import FormikForm from '../../components/FormikForm/FormikForm';
import { actions } from '../../redux';
import { Axios, NOTIFICATIONLIST_URL, NOTIFICATIONUPDATE_URL } from '../../utils/apiUtils';
import { SiteMap } from '../../Routes';
import * as Yup from "yup";
import { Regx } from '../../utils/Regx';
import { NotificationPriority, NotificationStatus } from '../../utils/constants';

function NotificationUpdatePage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  const Validator = Yup.object().shape({
    message: Yup.string()
      .required("Message Required.")
      .max(512, "Message exceeds Character limit.")
      .min(10, "Message too Short.")
      .matches(Regx.textWithSpclChar, "Invalid Message.")
  });

  React.useEffect(() => {
    if (Object.keys(data).length === 0)
      callSingleAPI(id);
  }, []);

  const callSingleAPI = async (id) => {
    await Axios.post(`${NOTIFICATIONLIST_URL}/${id}`, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        setData({...response.data.data[0]});
        setLoading(false);
      }
    }).catch( err=> {
    console.log("err res", err.response);
    if (err.response && err.response.data && err.response.data.msg )
      ShowToast({ "message": err.response.data.msg, "severity": "error" });
    else
      ShowToast({ "message": err.message, "severity": "error" });
  });
  }

  const handleSubmit = async (data) => {
    await callCreateAPI(data);
  }

  const callCreateAPI = async (inputPayload) => {
    Axios.post(`${NOTIFICATIONUPDATE_URL}/${id}`, inputPayload).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
        navigate(SiteMap.NotificationPage.path);
      }
    }).catch( err=> {
    console.log("err res", err.response);
    if (err.response && err.response.data && err.response.data.msg )
      ShowToast({ "message": err.response.data.msg, "severity": "error" });
    else
      ShowToast({ "message": err.message, "severity": "error" });
  });
  }


  return (
    <Layout >
      <div className='aj-main'>
        <Breadcrumbs aria-label="breadcrumb" className='aj-bread-crumbs'>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.DashboardPage.path)}}>
            Dashboard
          </Link>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.NotificationPage.path)}}>
            Notifications
          </Link>
          <Typography color="text.primary">Update Notifications</Typography>
        </Breadcrumbs>

        <Card sx={{ minWidth: 180}} className="aj-card-main">
          {
            loading? <>Loading...</> 
            :
            <React.Fragment>
              <div className="aj-card-heading back-primary">
                <h4>Update Notifications</h4>
              </div>
              <div className="aj-card-body">
                <FormikForm 
                  initialValues={data} 
                  validator={Validator}  
                  onSubmit={handleSubmit}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={{ xs: 0.5, sm:0.5, md: 1, lg: 1, xl:2 }}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormikTextField 
                          label="Message"
                          name="message"
                          className="aj-text-input"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikSelect
                          label="Priority"
                          size="small"
                          name="priority"
                          items={NotificationPriority}
                          defaultValue='Medium'
                          placeholder="Choose Status"
                          />
                          
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikSelect
                          label="Status"
                          size="small"
                          name="status"
                          items={NotificationStatus}
                          defaultValue='New'
                          placeholder="Choose Status"
                          />
                          
                      </Grid>
                    </Grid>
                    <br />
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" className='aj-card-footer'>
                      <Button variant="contained" color="primary" className="aj-btn-bold"  onClick={() => {navigate(SiteMap.NotificationPage.path)}}>
                        Back
                      </Button>
                      <Button variant="contained" type="submit" className='aj-btn-primary' >
                        Update
                      </Button>
                    </Stack>
                  </Box>
                </FormikForm>
              </div>
            </React.Fragment>
          }
        </Card>
      </div>
    </Layout>
  )
}

export default NotificationUpdatePage