import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ADMINLOGIN_URL, Axios, LOGIN_URL } from '../../utils/apiUtils';
import { actions } from '../../redux'
import { SiteMap } from '../../Routes';
import { useNavigate, useParams } from "react-router-dom";

const AdminLoginPage =() => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector(state => state.auth);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  const { RegisterLogin, RegisterLogout } = bindActionCreators(
    actions.auth, 
    dispatch
  );

  React.useEffect(() => {
    RegisterLogout();
    
    setTimeout(function(){
      callLoginAPI();
    }, 1000);
  }, []);

  const callLoginAPI = async (inputPayload) => {

    Axios.post(ADMINLOGIN_URL, {id:atob(id)}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        var expiryTime = new Date();
        expiryTime.setSeconds(expiryTime.getSeconds() + response.data.expiry_in);
        let auth_data = {
          user: {
            name: response.data.name,
            id: response.data.id
          },
          token: {
            access_token: response.data.access_token,
            expiry_in: response.data.expiry_in
          },
          token_expires_at: expiryTime
        }
        RegisterLogin(auth_data);
        ShowToast({ "message": response.data.msg, "severity": "success" });
        navigate(SiteMap.DashboardPage.path);
      }
    }).catch( err=> {
    console.log("err res", err.response);
    if (err.response && err.response.data && err.response.data.msg )
      ShowToast({ "message": err.response.data.msg, "severity": "error" });
    else
      ShowToast({ "message": err.message, "severity": "error" });
  });
  }


  return (
    <React.Fragment>
      logging in
    </React.Fragment>
  )
}

export default AdminLoginPage;