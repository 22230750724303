import { Box, Breadcrumbs, Button, Card, Grid, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { FormikTextField, Layout } from '../../components';
import FormikForm from '../../components/FormikForm/FormikForm';
import { actions } from '../../redux';
import { Axios, EXPENSELIST_URL, EXPENSEUPDATE_URL} from '../../utils/apiUtils';
import { SiteMap } from '../../Routes';
import * as Yup from "yup";
import { Regx } from '../../utils/Regx';
import FormikMultiTextField from '../../components/FormikMultiTextField/FormikMultiTextField';
import FormikDateField from '../../components/FormikDateField/FormikDateField';
import dayjs from 'dayjs';

function ExpenseUpdatePage() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hrData, setHRData] = useState({});
  const [loading, setLoading] = useState(true);
  const [value, setValue] = React.useState();

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  const Validator = Yup.object().shape({
    description: Yup.string()
      .required("Description Required.")
      .min(8, "Description too Short.")
      .matches(Regx.textWithSpclChar, "Invalid Description.")
  });

  React.useEffect(() => {
    if (Object.keys(hrData).length === 0)
      callSingleAPI(id);
  }, []);

  const callSingleAPI = async (id) => {
    await Axios.post(`${EXPENSELIST_URL}/${id}`, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        setHRData({...response.data.data[0]});
        setValue(response.data.data[0].from_date != null && response.data.data[0].from_date != undefined ? dayjs(response.data.data[0].from_date).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'));
        setLoading(false);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const handleSubmit = async (data) => {
    await callUpdateAPI(data);
  }

  const handleChange = (newValue) => {
    console.log('Enter here');
    setValue(newValue);
  };


  const callUpdateAPI = async (inputPayload) => {
    Axios.post(`${EXPENSEUPDATE_URL}/${id}`, inputPayload).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
        navigate(SiteMap.ExpensePage.path);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  return (
    <Layout >
      <div className='aj-main'>
        <Breadcrumbs aria-label="breadcrumb" className='aj-bread-crumbs'>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.DashboardPage.path)}}>
            Expenses
          </Link>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.ExpensePage.path)}}>
            Expenses
          </Link>
          <Typography color="text.primary">Update Expenses</Typography>
        </Breadcrumbs>

        <Card sx={{ minWidth: 180}} className="aj-card-main">
          {
            loading? <>Loading...</> 
            :
            <React.Fragment>
              <div className="aj-card-heading back-primary">
                <h4>Update Expenses</h4>
              </div>
              <div className="aj-card-body">
                <FormikForm 
                  initialValues={hrData} 
                  validator={Validator}  
                  onSubmit={handleSubmit}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={{ xs: 0.5, sm:0.5, md: 1, lg: 1, xl:2 }}>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikTextField 
                          label="Vechicle Name"
                          name="vechicle"
                          className="aj-text-input"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikTextField 
                          label="KM Covered"
                          name="km"
                          className="aj-text-input"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikTextField 
                          label="From Place"
                          name="from_place"
                          className="aj-text-input"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikTextField 
                          label="To Place"
                          name="to_place"
                          className="aj-text-input"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikDateField
                          label="Date"
                          name="day"
                          className="aj-text-input"
                          inputFormat="YYYY-MM-DD"
                          value={value}
                          setValue={setValue}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormikMultiTextField 
                          label="Description"
                          name="description"
                          className="aj-text-input"
                          multiline
                          rows={4}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" className='aj-card-footer'>
                      <Button variant="contained" color="primary" className="aj-btn-bold"  onClick={() => {navigate(SiteMap.ExpensePage.path)}}>
                        Back
                      </Button>
                      <Button variant="contained" type="submit" className='aj-btn-primary' >
                        Update
                      </Button>
                    </Stack>
                  </Box>
                </FormikForm>
              </div>
            </React.Fragment>
          }
        </Card>
      </div>
    </Layout>
  )
}

export default ExpenseUpdatePage