import { Box, Breadcrumbs, Button, Card, Grid, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { FormikSelect, FormikTextField, Layout } from '../../components';
import FormikForm from '../../components/FormikForm/FormikForm';
import { actions } from '../../redux';
import { Axios, TCLIST_URL, TCUPDATE_URL } from '../../utils/apiUtils';
import { TCStatus } from '../../utils/constants';
import { SiteMap } from '../../Routes';
import * as Yup from "yup";
import { Regx } from '../../utils/Regx';

function TelleCallersUpdatePage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tcData, setTCData] = useState({});
  const [loading, setLoading] = useState(true);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  const Validator = Yup.object().shape({
    name: Yup.string()
      .required("Username Required.")
      .max(60, "Username exceeds Character limit.")
      .min(6, "Username too Short.")
      .matches(Regx.plainText, "Invalid Username."),
    mobile: Yup.string()
      .min(10, '10 digit Mobile Number Required.')
      .max(10, '10 digit Mobile Number Required.'),
    email: Yup.string().email('Invalid Email Address.')
  });

  React.useEffect(() => {
    if (Object.keys(tcData).length === 0)
      callTCSingleAPI(id);
  }, []);

  const callTCSingleAPI = async (id) => {
    await Axios.post(`${TCLIST_URL}/${id}`, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        setTCData({...response.data.users[0]});
        setLoading(false);
      }
    }).catch( err=> {
    console.log("err res", err.response);
    if (err.response && err.response.data && err.response.data.msg )
      ShowToast({ "message": err.response.data.msg, "severity": "error" });
    else
      ShowToast({ "message": err.message, "severity": "error" });
  });
  }

  const handleSubmit = async (data) => {
    await callTCCreateAPI(data);

    console.log('enter till here');
  }

  const callTCCreateAPI = async (inputPayload) => {
    Axios.post(`${TCUPDATE_URL}/${id}`, inputPayload).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
        navigate(SiteMap.TelleCallersPage.path);
      }
    }).catch( err=> {
    console.log("err res", err.response);
    if (err.response && err.response.data && err.response.data.msg )
      ShowToast({ "message": err.response.data.msg, "severity": "error" });
    else
      ShowToast({ "message": err.message, "severity": "error" });
  });
  }


  return (
    <Layout >
      <div className='aj-main'>
        <Breadcrumbs aria-label="breadcrumb" className='aj-bread-crumbs'>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.DashboardPage.path)}}>
            Dashboard
          </Link>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.TelleCallersPage.path)}}>
            TeleCallers
          </Link>
          <Typography color="text.primary">Update Member</Typography>
        </Breadcrumbs>

        <Card sx={{ minWidth: 180}} className="aj-card-main">
          {
            loading? <>Loading...</> 
            :
            <React.Fragment>
              <div className="aj-card-heading back-primary">
                <h4>Update Member: {tcData.name ? tcData.name : ''}</h4>
              </div>
              <div className="aj-card-body">
                <FormikForm 
                  initialValues={tcData} 
                  validator={Validator}  
                  onSubmit={handleSubmit}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={{ xs: 0.5, sm:0.5, md: 1, lg: 1, xl:2 }}>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikTextField
                          label="Username"
                          name="name"
                          className="aj-text-input"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikTextField 
                          label="Mobile Number"
                          name="mobile"
                          className="aj-text-input"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikTextField 
                          label="Email Address"
                          name="email"
                          className="aj-text-input"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikTextField 
                          label="Monthly Target Amount"
                          name="monthly_tar_amount"
                          className="aj-text-input"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikTextField 
                          label="Daily Target Leads"
                          name="daily_tar_leads"
                          className="aj-text-input"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikSelect
                          label="Status"
                          size="small"
                          name="status"
                          id="status"
                          items={TCStatus}
                          placeholder="Choose Status"
                          />
                          
                      </Grid>
                    </Grid>
                    <br />
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" className='aj-card-footer'>
                      <Button variant="contained" color="primary" className="aj-btn-bold"  onClick={() => {navigate(SiteMap.TelleCallersPage.path)}}>
                        Back
                      </Button>
                      <Button variant="contained" type="submit" className='aj-btn-primary' >
                        Update
                      </Button>
                    </Stack>
                  </Box>
                </FormikForm>
              </div>
            </React.Fragment>
          }
        </Card>
      </div>
    </Layout>
  )
}

export default TelleCallersUpdatePage