export const HRStatus = [
  { label: 'Active', value: "Active" },
  { label: 'Pending', value: "Pending" },
  { label: 'Suspended', value: "Suspended" }
]

export const TCStatus = [
  { label: 'Active', value: "Active" },
  { label: 'Pending', value: "Pending" },
  { label: 'Suspended', value: "Suspended" }
]

export const LeadsStatus = [
  { label: 'New', value: "New" },
  { label: 'Pending', value: "Pending" },
  { label: 'Following', value: "Following" },
  { label: 'Not Responding', value: "Not Responding" },
  { label: 'Cancelled', value: "Cancelled" },
  { label: 'Completed', value: "Completed" },
  { label: 'Deleted', value: "Deleted" }
]

export const NotificationStatus = [
  { label: 'New', value: "New" },
  { label: 'Completed', value: "Completed" },
  { label: 'Postpond', value: "Postpond" }
]

export const LeavesStatus = [
  { label: 'Pending', value: "Pending" },
  { label: 'Accepted', value: "Accepted" },
  { label: 'Rejected', value: "Rejected" }
]

export const ExpenseStatus = [
  { label: 'Pending', value: "Pending" },
  { label: 'Accepted', value: "Accepted" },
  { label: 'Rejected', value: "Rejected" }
]

export const NotificationPriority = [
  { label: 'Low', value: "Low" },
  { label: 'Medium', value: "Medium" },
  { label: 'High', value: "High" }
]

export const ALLLimits = [
  { label: 10, value: 10 },
  { label: 25, value: 25 },
  { label: 100, value: 100 }
]