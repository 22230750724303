import React from "react";
import { Field } from "formik";
import { FormHelperText, Stack, TextField } from "@mui/material";

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useEffect } from "react";
import dayjs from 'dayjs';

const FormikDateField = props => {
  
	const {
		variant,
		name,
		label,
		placeholder,
		defaultValue,
		className,
		inputFormat,
		onChange,
		value,
		disabled,
		size,
		setValue
	} = props;

	const Content = ({ form, meta }) => {

	  React.useEffect(() => {
			form.setFieldValue(name, dayjs(value).format('YYYY-MM-DD'));
		}, []);

		return (
			<React.Fragment>
				<LocalizationProvider dateAdapter={AdapterDayjs} size="small">
          <Stack spacing={2}>
            <DesktopDatePicker
              fullWidth
              name={name}
              id={name}
              value={value}
              placeholder={placeholder}
              label={label}
              variant={variant || "outlined"}
              // defaultValue={meta.initialValue || defaultValue}
              error={(meta.touched || form.submitCount > 0) && Boolean(meta.error)}
              helperText={(meta.touched || form.submitCount > 0) && meta.error}
              className={className || ""}
              inputFormat={inputFormat}
              onChange={newValue => {
								setValue(dayjs(newValue).format('YYYY-MM-DD'));
								form.setFieldValue(name, dayjs(newValue).format('YYYY-MM-DD'));
							}}
              disabled={disabled}
              size={size}
              renderInput={(params) => <TextField {...params} />}
				    />
          </Stack>
        </LocalizationProvider>
			</React.Fragment>
		);
	};
	return (
		<React.Fragment>
			<Field name={name}>{Content}</Field>
		</React.Fragment>
	);
};

export default FormikDateField;
