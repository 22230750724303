import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import LoginPage from "./pages/login/LoginPage";
import DashboardPage from "./pages/home/DashboardPage";
import { useSelector } from "react-redux";
import { Error404 } from "./components";
import TelleCallersPage from "./pages/tellecallers/TelleCallersPage";
import TelleCallersCreatePage from "./pages/tellecallers/TelleCallersCreatePage";
import TelleCallersUpdatePage from "./pages/tellecallers/TelleCallersUpdatePage";
import LeadsPage from "./pages/leads/LeadsPage";
import LeadsCreatePage from "./pages/leads/LeadsCreatePage";
import LeadsUpdatePage from "./pages/leads/LeadsUpdatePage";
import NotificationPage from "./pages/notifications/NotificationPage";
import NotificationCreatePage from "./pages/notifications/NotificationCreatePage";
import NotificationUpdatePage from "./pages/notifications/NotificationUpdatePage";
import AdminLoginPage from "./pages/login/AdminLoginPage";
import LeavesPage from "./pages/leaves/LeavesPage";
import WorkSpacePage from "./pages/workspace/WorkSpacePage";
import ExpensePage from "./pages/expense/ExpensePage";
import ExpenseUpdatePage from "./pages/expense/ExpenseUpdatePage";
import LeadsStatusLogsPage from "./pages/leads/LeadsStatusLogsPage";
import TelleReport from "./pages/reports/TelleReport";
import TelleCallersLocationPage from "./pages/tellecallers/TelleCallersLocationPage";

export const SiteMap = {
	HomePage: { path: "/" },
  DashboardPage: { path: "/dashboard" },
	AdminLoginPage: { path: "/admin/:id" },
	
  TelleCallersPage: { path: "/tellecallers" },
  TelleCallersCreatePage: { path: "/tellecallers/create" },
	TelleCallersUpdatePage: { path: "/tellecallers/:id" },
	TelleCallersLocationPage: { path: "/tellecallers/location/:id" },

	LeadsPage: { path: "/leads" },
	LeadsCreatePage: { path: "/leads/create" },
	LeadsUpdatePage: { path: "/leads/:id" },
	LeadsStatusLogsPage: { path: "/leads/statuslogs/:id" },

	NotificationPage: { path: "/notifications" },
	NotificationCreatePage: { path: "/notifications/create" },
	NotificationUpdatePage: { path: "/notifications/:id" },

	LeavesPage: { path: "/leaves" },
	LeavesCreatePage: { path: "/leaves/create" },
	LeavesUpdatePage: { path: "/leaves/:id" },

	WorkSpacePage: { path: "/workspace" },
	WorkSpaceCreatePage: { path: "/workspace/create" },
	WorkSpaceUpdatePage: { path: "/workspace/:id" },

	ExpensePage: { path: "/expense" },
	ExpenseCreatePage: { path: "/expense/create" },
	ExpenseUpdatePage: { path: "/expense/:id" },

	TelleReport: { path: "/tellereport" },

}

const PrivateRoutesWrapper = ({ isUser }) => {
	return isUser ? <Outlet /> : <Navigate to={SiteMap.HomePage.path} />;
}

const PublicRoutesWrapper = ({ isUser }) => {
	return !isUser ? <Outlet /> : <Navigate to={SiteMap.DashboardPage.path} />;
}

const RouteItems = () => {
	const authState = useSelector(state => state.auth);
  const { isSessionValid } = authState;

  return (
    <Routes>
			<Route path={SiteMap.AdminLoginPage.path} element={<AdminLoginPage />} />
      {/* Public Routes */}
			<Route path="/" element={<PublicRoutesWrapper isUser={isSessionValid} />}>
				<Route path={SiteMap.HomePage.path} element={<LoginPage />} />
			</Route>

      {/* Private Routes */}
			<Route path="/" element={<PrivateRoutesWrapper isUser={isSessionValid} />}>
				<Route path={SiteMap.DashboardPage.path} element={<DashboardPage />} />

				<Route path={SiteMap.TelleCallersPage.path} element={<TelleCallersPage />} />
				<Route path={SiteMap.TelleCallersCreatePage.path} element={<TelleCallersCreatePage />} />
				<Route path={SiteMap.TelleCallersUpdatePage.path} element={<TelleCallersUpdatePage />} />
				<Route path={SiteMap.TelleCallersLocationPage.path} element={<TelleCallersLocationPage />} />

				<Route path={SiteMap.LeadsPage.path} element={<LeadsPage />} />
				<Route path={SiteMap.LeadsCreatePage.path} element={<LeadsCreatePage />} />
				<Route path={SiteMap.LeadsUpdatePage.path} element={<LeadsUpdatePage />} />
				<Route path={SiteMap.LeadsStatusLogsPage.path} element={<LeadsStatusLogsPage />} />

				<Route path={SiteMap.NotificationPage.path} element={<NotificationPage />} />
				<Route path={SiteMap.NotificationCreatePage.path} element={<NotificationCreatePage />} />
				<Route path={SiteMap.NotificationUpdatePage.path} element={<NotificationUpdatePage />} />

				<Route path={SiteMap.LeavesPage.path} element={<LeavesPage />} />

				<Route path={SiteMap.WorkSpacePage.path} element={<WorkSpacePage />} />

				<Route path={SiteMap.ExpensePage.path} element={<ExpensePage />} />
				<Route path={SiteMap.ExpenseUpdatePage.path} element={<ExpenseUpdatePage />} />

				<Route path={SiteMap.TelleReport.path} element={<TelleReport />} />

			</Route>



			<Route path="*" element={<Error404 />} />
    </Routes>
  )
}

export default RouteItems;