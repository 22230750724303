import axios from 'axios';
import { store } from "../redux/store";

function getToken() {
    let token = store.getState().auth.token.access_token;
    return token;
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

axios.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});

export const LOGIN_URL = '/hr/login';
export const HOME_URL = '/hr/home';
export const ADMINLOGIN_URL = '/hr/admin/login';

export const TCCREATE_URL = '/hr/tellecallers/create';
export const TCLIST_URL = '/hr/tellecallers';
export const TCUPDATE_URL = '/hr/tellecallers/update';
export const TCDELETE_URL = '/hr/tellecallers/delete';
export const TCCHANGE_URL = '/hr/tellecallers/password';
export const TCDSTATUS_URL = '/hr/tellecallers/status';
export const TCSEARCH_URL = '/hr/tellecallers/search';


export const TCLOCATIONLIST_URL = '/telecaller/location/address/get';

export const LEADSCREATE_URL = '/telecaller/leads/create';
export const LEADSLIST_URL = '/telecaller/leads';
export const LEADSUPDATE_URL = '/telecaller/leads/update';
export const LEADSDELETE_URL = '/telecaller/leads/delete';
export const LEADSSTATUS_URL = '/telecaller/leads/status';
export const LEADSTELLE_URL = '/telecaller/leads/telle';
export const LEADSAMOUNT_URL = '/telecaller/leads/amount';
export const LEADSSTATUSCHANGELIST_URL = '/telecaller/leads/statuslogs';

export const NOTIFICATIONCREATE_URL = '/notifications/create';
export const NOTIFICATIONLIST_URL = '/notifications';
export const NOTIFICATIONUPDATE_URL = '/notifications/update';
export const NOTIFICATIONDELETE_URL = '/notifications/delete';
export const NOTIFICATIONSTATUS_URL = '/notifications/status';
export const NOTIFICATIONPRIORITY_URL = '/notifications/priority';

export const SERVICESLIST_URL = '/home/services/search';

export const STATESEARCH_URL = '/location/state/search';
export const DISTRICTSEARCH_URL = '/location/district/search';

export const LEAVELIST_URL = '/leaves';
export const LEAVEDELETE_URL = '/leaves/delete';
export const LEAVESTATUS_URL = '/leaves/status';

export const WORKSPACECREATE_URL = '/workspace/create';
export const WORKSPACELIST_URL = '/workspace';
export const WORKSPACEUPDATE_URL = '/workspace/update';

export const EXPENSECREATE_URL = '/expense/create';
export const EXPENSELIST_URL = '/expense';
export const EXPENSEUPDATE_URL = '/expense/update';
export const EXPENSESTATUS_URL = '/expense/status';





export const TELLEREPORT_URL = '/telecaller/home';

export const Axios = axios;
