import { combineReducers } from "redux";
import storage from 'redux-persist/lib/storage';
import {
    persistReducer,
} from 'redux-persist';

import { AuthReducers } from './authReducers';
import { ToastReducer } from "./toastReducers";

const persistConfig = {
    key: 'counter',
    storage,
    blacklist: ['toast']
};

const reducers = combineReducers({
    auth: AuthReducers,
    toast: ToastReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export default persistedReducer;