import { Breadcrumbs,  Button,  Card,FormControl, Grid, InputLabel, Link, MenuItem,  Select,Stack,TextField,Typography } from '@mui/material';
import React, { useState } from 'react';
import { Layout } from '../../components';
import { SiteMap } from '../../Routes';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Axios, TCSEARCH_URL, TELLEREPORT_URL } from '../../utils/apiUtils';
import { bindActionCreators } from 'redux';
import { actions } from '../../redux';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';

const TelleReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector(state => state.auth);

  const [tcdata , setTCData] = useState([]);
  const [search, setSearch] = useState('');
  const [telle_id, setTelle] = useState(0);
  const [lists, setLists] = useState([]);
  const [isSet, setIsSet] = useState(false);
  const [todayDate, setTodayDate] = React.useState(dayjs().format('YYYY-MM-DD'));

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callTCSearchListAPI(search);
  }, [search]);

  const handleDateChange = (newValue) => {
    setTodayDate(dayjs(newValue).format('YYYY-MM-DD'));
  };


  const callTCSearchListAPI =  (search) => {
    Axios.post(TCSEARCH_URL, {search:search, hr_id: authState.user.id, limit:1000}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        setTCData(response.data.states);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const callListAPI = async (show_page) => {
    if( show_page === undefined || show_page === null) show_page = 1;

    Axios.post(TELLEREPORT_URL, {hr_id: authState.user.id, user_id:telle_id, tdate: todayDate}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLists(response.data);
        setIsSet(true);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  return (    
  <Layout >
    <div className='aj-main'>
      <Breadcrumbs aria-label="breadcrumb" className='aj-bread-crumbs'>
        <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.DashboardPage.path)}}>
          Dashboard
        </Link>
        <Typography color="text.primary">Tellecallers Report</Typography>
      </Breadcrumbs>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={{ xs: 0.5, sm:0.5, md: 1, lg: 1, xl:2 }}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="hr-telle-label" >Telle Caller</InputLabel>
              <Select
                labelId="hr-telle-label"
                id="hr-telle"
                label="Telle Caller"
                size="small"
                name="telle_id"
                defaultValue="All"
                onChange={ (e) => {setTelle(e.target.value)} }
              >
                <MenuItem id="all" key="all" value="All">Choose Telle Caller</MenuItem>
                {tcdata &&
                  tcdata.map(option => (
                    <MenuItem id={option.id} key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <Stack spacing={2}>
                <DesktopDatePicker
                  size="small"
                  label="From Date"
                  name="from_date"
                  className="aj-text-input"
                  value={todayDate}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={1}>
            <Button
              variant="contained"
              type="submit"
              className='aj-btn-primary'
              id="hr-submit"
              onClick={ () => {callListAPI()} }
              fullWidth
              >
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
      <br />
    
      <Card > 
        
      <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 0.5, sm:0.5, md: 1, lg: 1, xl:2 }}>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-danger b'>Todays Target</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? Math.round(lists.dailyTarget)  : '0' }
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-danger b'>Monthly Target</p>
                <p className='aj-content text-muted b'>
                  ₹{ isSet === true ? Math.round(lists.monthlyTarget)  : '0' }
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-success b'>Month Income</p>
                <p className='aj-content text-muted b'>
                  ₹{ isSet === true ? Math.round(lists.leadsMonthIncome)  : '0' }
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-danger b'>Month Expense</p>
                <p className='aj-content text-muted b'>
                  ₹{ isSet === true ? Math.round(lists.leadsMonthExpense)  : '0' }
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-success b'>Todays Income</p>
                <p className='aj-content text-muted b'>
                  ₹{ isSet === true ? Math.round(lists.leadsTodayIncome)  : '0' }
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-danger b'>Todays Expense</p>
                <p className='aj-content text-muted b'>
                  ₹{ isSet === true ? Math.round(lists.leadsTodayExpense)  : '0' }
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-success b'>Total Income</p>
                <p className='aj-content text-muted b'>
                  ₹{ isSet === true ? Math.round(lists.leadsIncome)  : '0' }
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-danger b'>Total Expense</p>
                <p className='aj-content text-muted b'>
                  ₹{ isSet === true ? Math.round(lists.leadsExpense)  : '0' }
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Total Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.totalLeads  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>New Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.new  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Pending Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.pending  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Following Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.following  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Not Responding Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.notresponding  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Cancelled Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.cancelled  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Completed Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.completed  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Deleted Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.deleted  : '0' }
                  </p>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </div>
  </Layout>
  )
}

export default TelleReport