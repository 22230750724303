import { Breadcrumbs, Button, Card, FormControl, Grid, InputLabel, Link, MenuItem, Modal, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,  Typography } from '@mui/material';
import React, { useState } from 'react';
import { Layout } from '../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { SiteMap } from '../../Routes';
import { TCLOCATIONLIST_URL } from '../../utils/apiUtils';
import { actions } from '../../redux';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Axios } from '../../utils/apiUtils';
import { ALLLimits } from '../../utils/constants';
import moment from 'moment';
import { Box } from '@mui/system';

function TelleCallersLocationPage() {
  const { id } = useParams();
  const authState = useSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modelList, setModelLists] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);


  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callPageDataListAPI();
  }, []);

  
  const callPageDataListAPI = async (show_page) => {
    if( show_page === undefined || show_page === null) show_page = 1;

    Axios.post(TCLOCATIONLIST_URL, {page: show_page, limit: limit, tc_id:id}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setModelLists(response.data.users);
        setCount( Math.ceil(response.data.count / limit));
        setPage(response.data.page);
      }
    }).catch( err=> {
    console.log("err res", err.response);
    if (err.response && err.response.data && err.response.data.msg )
      ShowToast({ "message": err.response.data.msg, "severity": "error" });
    else
      ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const handlePageChange = (event, newPage)=>{
    callPageDataListAPI(newPage);
  }



  return (
    
    <Layout >
      <div className='aj-main'>
        <Breadcrumbs aria-label="breadcrumb" className='aj-bread-crumbs'>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.DashboardPage.path)}}>
            Dashboard
          </Link>
          <Typography color="text.primary">TeleCallers Locations</Typography>
        </Breadcrumbs>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 0.5, sm:0.5, md: 1, lg: 1, xl:2 }}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <FormControl fullWidth>
                <InputLabel id="tc-limit-label" >Limit</InputLabel>
                <Select
                  labelId="tc-limit-label"
                  id="tc-limit"
                  label="Limit"
                  size="small"
                  name="limit"
                  defaultValue={10}
                  onChange={ (e) => {setLimit(e.target.value)} }
                  className="aj-text-input"
                >
                  {ALLLimits &&
                    ALLLimits.map(option => (
                      <MenuItem id={option.value} key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={1}>
              <Button
                variant="contained"
                type="submit"
                className='aj-btn-primary'
                id="tc-submit"
                onClick={ () => {callPageDataListAPI()} }
                fullWidth
                >
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>
        <br />
      
        <Card >
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size='small' className='aj-resp-table'>
              <TableHead className='aj-table-primary'>
                <TableRow>
                  <TableCell className='text-center'>ID</TableCell>
                  <TableCell className='text-center'>Address</TableCell>
                  <TableCell className='text-center'>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { count > 0 ? 
                  modelList.map( (tc) => 
                    <TableRow key={tc.id} >
                      <TableCell align="center">{tc.id}</TableCell>
                      <TableCell>{tc.address}</TableCell>
                      <TableCell>{ tc.created_at ? moment(tc.created_at).format("YYYY-MM-DD kk:mm:00") : '-' }</TableCell>
                    </TableRow>
                  ) 
                : 
                  <TableRow key={0} >
                    <TableCell colSpan={7} align="center">No Results Found!</TableCell>
                  </TableRow>
                }
              </TableBody>
              {count > 0 ?
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Pagination count={count} page={page} onChange={handlePageChange} color="primary" shape="rounded" />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              : '' }
            </Table>
          </TableContainer>
        </Card>
      </div>
    </Layout>
  )
}

export default TelleCallersLocationPage