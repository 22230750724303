import React from "react";
import { Field } from "formik";
import {
	FormHelperText,
	FormControl,
	Autocomplete,
	TextField,
} from "@mui/material";
import { useState } from "react";

const FormikAutoComplete = props => {
	const { name, label, className, items, setSearch, setID, onChange, reset, onBlur} = props;


	const Content = ({ form, meta }) => {
		const [value, setValue] = useState(null);

		React.useEffect(() => {
			if(reset) setValue('');
		}, [reset]);

		React.useEffect(()=>{
			for(var i = 0; i < items.length; i++) {
				if(items[i].id  == meta.initialValue) {
					setValue(items[i]);
				}
			}
		},[items]);

		return (
			<React.Fragment>
				<FormControl fullWidth error={Boolean(meta.error)}>
						<Autocomplete
							size='small'
							fullWidth
							className={className}
							id={name}
							name={name}
							disablePortal
							options={items}
							renderInput={(params) => (
								<TextField 
									error={Boolean(meta.error)}
									{...params} 
									label={label} 
									fullWidth 
								/>
							)}
							onKeyUp={(e) => { if(setSearch) setSearch(e.target.value) }}
							value={value}
							onChange={(e, newValue) => {
									if(newValue && newValue.id > 0) {
										form.setFieldValue(name, newValue.id);
										setValue(newValue);
										// if(setID) setID(newValue.id);
									} else {
										form.setFieldValue(name, '');
										setValue('');
										// if(setID) setID('');
									}
									onChange(e, newValue);
								}
							}
						/>
					
					{(meta.touched || form.submitCount > 0) && meta.error && (
						<FormHelperText>{meta.error}</FormHelperText>
					)}
				</FormControl>
			</React.Fragment>
		);
	};
	return (
		<React.Fragment>
			<Field name={name}>{Content}</Field>
		</React.Fragment>
	);
};

export default FormikAutoComplete;
