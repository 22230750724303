import React from 'react'

function Footer() {
  return (
    <div className="aj-footer">
      <p>Copyright © 2021-2022 Seclob. All rights reserved.</p>
    </div>
  )
}

export default Footer