import { Breadcrumbs, Card, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Layout } from '../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { SiteMap } from '../../Routes';
import { actions } from '../../redux';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { Axios, LEADSSTATUSCHANGELIST_URL } from '../../utils/apiUtils';
import moment from 'moment';

const LeadsStatusLogsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const { id } = useParams();
  const [lists, setLists] = useState([]);
  const [count, setCount] = useState(0);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callListAPI();
  }, []);


  const callListAPI = async (show_page) => {
    if( show_page === undefined || show_page === null) show_page = 1;
    Axios.post(`${LEADSSTATUSCHANGELIST_URL}/${id}`).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLists(response.data.resp);
        setCount(response.data.count);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }


  return (
    <Layout >
      <div className='aj-main'>
        <Breadcrumbs aria-label="breadcrumb" className='aj-bread-crumbs'>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.DashboardPage.path)}}>
            Dashboard
          </Link>
          <Typography color="text.primary">Leads</Typography>
          <Typography color="text.primary">Leads Status Change Logs</Typography>
        </Breadcrumbs>
        <br />
      
        <Card >
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size='small' className='aj-resp-table'>
              <TableHead className='aj-table-primary'>
                <TableRow>
                  <TableCell className='text-center'>ID</TableCell>
                  <TableCell className='text-center'>Telle Caller</TableCell>
                  <TableCell className='text-center'>Status</TableCell>
                  <TableCell className='text-center'>Comments</TableCell>
                  <TableCell className='text-center'>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { count > 0 ? 
                  lists.map( (resp) => {

                    return(
                      <TableRow key={resp.id} >
                        <TableCell align="center">{resp.id}</TableCell>
                        <TableCell>{resp.tellename}</TableCell>
                        <TableCell>{resp.status}</TableCell>
                        <TableCell>{resp.comments}</TableCell>
                        <TableCell>{ resp.created_at ? moment(resp.created_at).format("YYYY-MM-DD kk:mm:00") : '-' } </TableCell>


                      </TableRow>
                    );
                  }) 
                : 
                  <TableRow key={0} >
                    <TableCell colSpan={9} align="center">No Results Found!</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
        


    </Layout>
  )
}

export default LeadsStatusLogsPage