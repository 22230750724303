import { Box, Breadcrumbs, Grid } from '@mui/material';
import React, { useState } from 'react';
import Link from '@mui/material/Link';
import { Layout } from '../../components';
import { useNavigate } from 'react-router-dom';
import { SiteMap } from '../../Routes';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../redux';
import { bindActionCreators } from 'redux';
import { Axios, HOME_URL } from '../../utils/apiUtils';


function Dashboard() {
  const authState = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [lists, setLists] = useState([]);
  const [isSet, setIsSet] = useState(false);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callHomeApi();
  }, []);

  const callHomeApi = async (show_page) => {
    if( show_page === undefined || show_page === null) show_page = 1;

    Axios.post(HOME_URL, {user_id: authState.user.id}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLists(response.data);
        setIsSet(true);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  return (
    <Layout >
      <div className='aj-main'>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 0.5, sm:0.5, md: 1, lg: 1, xl:2 }}>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Total Telle Callers</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.totalTC  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-success b'>Active Telle Callers</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.totalActiveTC  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-info b'>Pending Telle Callers</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.totalPendingTC  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-danger b'>Suspended Telle Callers</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.totalSuspendedTC  : '0' }
                  </p>
              </div>
            </Grid>

            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Total Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.totalLeads  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>New Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.new  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Pending Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.pending  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Following Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.following  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Not Responding Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.notresponding  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Cancelled Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.cancelled  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Completed Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.completed  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Deleted Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.deleted  : '0' }
                  </p>
              </div>
            </Grid>

          </Grid>
        </Box>


      </div>
    </Layout>
  )
}

export default Dashboard