import { Breadcrumbs, Button, Card, Divider, Fab, FormControl, Grid, InputLabel, Link, MenuItem, Modal, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Layout } from '../../components';
import { useNavigate } from 'react-router-dom';
import { SiteMap } from '../../Routes';
import { Add as AddIcon } from '@mui/icons-material';
import { actions } from '../../redux';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Axios, NOTIFICATIONLIST_URL, NOTIFICATIONPRIORITY_URL, NOTIFICATIONSTATUS_URL, NOTIFICATIONDELETE_URL, TCSEARCH_URL } from '../../utils/apiUtils';
import moment from 'moment';
import { Box } from '@mui/system';
import { ALLLimits, NotificationPriority, NotificationStatus } from '../../utils/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FormikAutoComplete from '../../components/FormikAutoComplete/FormikAutoComplete';

const NotificationPage = () => {
  const authState = useSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [lists, setLists] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('All');
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [id, setId] = useState(0);
  const [tcdata , setTCData] = useState([]);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callListAPI();
    callTCSearchListAPI(search);
  }, []);

  const callTCSearchListAPI =  (search) => {
    Axios.post(TCSEARCH_URL, {search:search, hr_id: authState.user.id, limit:1000}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        setTCData(response.data.result);
        setTCData((tcdata) => [{label:'All Telecallers',id:'0'}, ...tcdata]);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const callListAPI = async (show_page) => {
    if( show_page === undefined || show_page === null) show_page = 1;

    Axios.post(NOTIFICATIONLIST_URL, {page: show_page, search: search, status: status, type:'HR-TC', limit: limit, user_id: authState.user.id}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLists(response.data.data);
        setCount( Math.ceil(response.data.count / limit));
        setPage(response.data.page);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }


  const handlePageChange = (event, newPage)=>{
    callListAPI(newPage);
  }

  const handleChangeStatus = (event, id) => {
    Axios.post(`${NOTIFICATIONSTATUS_URL}/${id}`, {status: event.target.value}).then((response) => {
      if (response.data.sts === "00") {
        ShowToast({ "message": response.data.msg, "severity": "error" });
      } else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  };
  
  const handleChangePriority = (event, id) => {
    Axios.post(`${NOTIFICATIONPRIORITY_URL}/${id}`, {priority: event.target.value}).then((response) => {
      if (response.data.sts === "00") {
        ShowToast({ "message": response.data.msg, "severity": "error" });
      } else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  };

  const handleDeleteModel = (id) => {
    setId(id);
    setOpen(true);
  }

  const handleDelete = () => {
    callDeleteAPI()
  }

  const callDeleteAPI = async () => {
    Axios.post(`${NOTIFICATIONDELETE_URL}/${id}`).then((response) => {
      setOpen(false);
      if (response.data.sts === "00") {
        ShowToast({ "message": response.data.msg, "severity": "error" });
      } else {
        callListAPI(1);
        ShowToast({ "message": response.data.msg, "severity": "success" });
      }
    }).catch( err=> {
    console.log("err res", err.response);
    if (err.response && err.response.data && err.response.data.msg )
      ShowToast({ "message": err.response.data.msg, "severity": "error" });
    else
      ShowToast({ "message": err.message, "severity": "error" });
  });
  }
  
  
  return (
    <Layout >
      <div className='aj-main'>
        <Breadcrumbs aria-label="breadcrumb" className='aj-bread-crumbs'>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.DashboardPage.path)}}>
            Dashboard
          </Link>
          <Typography color="text.primary">Notifications</Typography>
        </Breadcrumbs>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 0.5, sm:0.5, md: 1, lg: 1, xl:2 }}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <TextField
                label="Search"
                name="search"
                id="hr-search"
                className="aj-text-input"
                size="small"
                fullWidth
                onChange={ (e) => {setSearch(e.target.value)} }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <FormControl fullWidth>
                <InputLabel id="hr-status-label" >Status</InputLabel>
                <Select
                  labelId="hr-status-label"
                  id="hr-status"
                  label="Status"
                  size="small"
                  name="status"
                  defaultValue="All"
                  onChange={ (e) => {setStatus(e.target.value)} }
                >
                  <MenuItem id="all" key="all" value="All">Choose Status</MenuItem>
                  { NotificationStatus && NotificationStatus.map(option => (
                    <MenuItem id={option.value} key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <FormControl fullWidth>
                <InputLabel id="hr-limit-label" >Limit</InputLabel>
                <Select
                  labelId="hr-limit-label"
                  id="hr-limit"
                  label="Limit"
                  size="small"
                  name="limit"
                  defaultValue={10}
                  onChange={ (e) => {setLimit(e.target.value)} }
                >
                  {ALLLimits &&
                    ALLLimits.map(option => (
                      <MenuItem id={option.value} key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={1}>
              <Button
                variant="contained"
                type="submit"
                className='aj-btn-primary'
                id="hr-submit"
                onClick={ () => {callListAPI()} }
                fullWidth
                >
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>
        <br />
      
        <Card >
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size='small' className='aj-resp-table'>
              <TableHead className='aj-table-primary'>
                <TableRow>
                  <TableCell className='text-center'>ID</TableCell>
                  <TableCell className='text-center'>Message</TableCell>
                  <TableCell className='text-center'>To User</TableCell>
                  <TableCell className='text-center'>Created At</TableCell>
                  <TableCell className='text-center'>Priority</TableCell>
                  <TableCell className='text-center'>Status</TableCell>
                  <TableCell className='text-center'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { count > 0 ? 
                  lists.map( (hr) => {
                    return(
                      <TableRow key={hr.id} >
                        <TableCell align="center">{hr.id}</TableCell>
                        <TableCell className='td-manage-md'>{hr.message}</TableCell>
                        <TableCell >
                          <FormControl fullWidth size="small">
                            <Select
                              name={hr.id ? `to_user_id-${hr.id}` : ''}
                              defaultValue={hr.to_user_id}
                              disabled
                            >
                              { tcdata &&  tcdata.map(option => (
                                <MenuItem id={option.id} key={option.id} value={option.id}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>{ hr.created_at ? moment(hr.created_at).format("YYYY-MM-DD kk:mm:00") : '-' }</TableCell>
                        <TableCell >
                          <FormControl fullWidth size="small">
                            <Select
                              name={hr.id ? `priority-${hr.id}` : ''}
                              defaultValue={hr.priority}
                              onChange={(e)=>{handleChangePriority(e, hr.id)}}
                            >
                              { NotificationPriority &&  NotificationPriority.map(option => (
                                <MenuItem id={option.value} key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell >
                          <FormControl fullWidth size="small">
                            <Select
                              name={hr.id ? `status-${hr.id}` : ''}
                              defaultValue={hr.status}
                              onChange={(e)=>{handleChangeStatus(e, hr.id)}}
                            >
                              { NotificationStatus &&  NotificationStatus.map(option => (
                                <MenuItem id={option.value} key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell align="center">
                          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                            <Button variant="contained" color="warning" startIcon={<EditIcon />} className="aj-btn-bold" size="medium" onClick={() => {navigate(SiteMap.NotificationPage.path+'/'+hr.id)}}>
                              Edit
                            </Button>
                            <Button variant="contained" color="error" startIcon={<DeleteIcon />} className="aj-btn-bold" size="medium" onClick={() => handleDeleteModel(hr.id)}>
                              Delete
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  }) 
                : 
                  <TableRow key={0} >
                    <TableCell colSpan={9} align="center">No Results Found!</TableCell>
                  </TableRow>
                }
              </TableBody>
              {count > 0 ?
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <Pagination count={count} page={page} onChange={handlePageChange} color="primary" shape="rounded" />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              : '' }
            </Table>
          </TableContainer>
        </Card>
      </div>
        
      <Fab color="secondary" aria-label="add" className='aj-add-icon'  onClick={() => {navigate(SiteMap.NotificationCreatePage.path)}}>
        <AddIcon />
      </Fab>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="aj-model-box">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete Notification
          </Typography>
          <Divider />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure do you really want to Delete this Notification ?
          </Typography>
          <br />
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" className='aj-card-footer'>
            <Button variant="contained" color="primary" className="aj-btn-bold" size="small" onClick={() => setOpen(false)}>
              Close
            </Button>
            <Button variant="contained" color="error" className="aj-btn-bold" size="small" onClick={ () => handleDelete()}>
              Delete
            </Button>
          </Stack>
        </Box>
      </Modal>

    </Layout>
  )
}

export default NotificationPage