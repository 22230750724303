import React from "react";
import { Field } from "formik";
import {
	Select,
	MenuItem,
	FormHelperText,
	FormControl,
	InputLabel,
} from "@mui/material";

const FormikSelect = props => {
	const { name, label, placeholder, defaultValue, className, items } =
		props;

	const Content = ({ form, meta }) => {
		return (
			<React.Fragment>
				<FormControl fullWidth error={Boolean(meta.error)}>
					{label && <InputLabel id={`label-${name}`} >{label}</InputLabel>}
					<Select
            labelId={`label-${name}`}
            label={label}
            size='small'
            fullWidth
						className={className}
						id={name}
						name={name}
						defaultValue={meta.initialValue ||defaultValue || ""}
						onChange={e => {
							form.setFieldValue(name, e.target.value);
						}}
						error={
							(meta.touched || form.submitCount > 0) && Boolean(meta.error)
						}
					>
						<MenuItem value="" id="placeholder">{placeholder || ""}</MenuItem>
						{items &&
							items.map(option => (
								<MenuItem id={option.value} key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
					</Select>

					{(meta.touched || form.submitCount > 0) && meta.error && (
						<FormHelperText>{meta.error}</FormHelperText>
					)}
				</FormControl>
			</React.Fragment>
		);
	};
	return (
		<React.Fragment>
			<Field name={name}>{Content}</Field>
		</React.Fragment>
	);
};

export default FormikSelect;
