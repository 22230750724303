import { Breadcrumbs, Button, Card, Link, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FormikTextField, Layout, FormikSelect } from '../../components';
import { useNavigate } from 'react-router-dom';
import { SiteMap } from '../../Routes';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import FormikForm from '../../components/FormikForm/FormikForm';
import * as Yup from "yup";
import { Regx } from '../../utils/Regx';
import { Axios, NOTIFICATIONCREATE_URL, TCSEARCH_URL } from '../../utils/apiUtils';
import { bindActionCreators } from 'redux';
import { actions } from '../../redux';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationPriority, NotificationStatus } from '../../utils/constants';
import FormikAutoComplete from '../../components/FormikAutoComplete/FormikAutoComplete';

function NotificationCreatePage() {
  const authState = useSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tcdata , setTCData] = useState([]);
  const [search, setSearch] = useState('');

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callTCSearchListAPI(search);
  }, [search]);

  const callTCSearchListAPI =  (search) => {
    Axios.post(TCSEARCH_URL, {search:search, hr_id: authState.user.id, showAll99:true}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        setTCData(response.data.result);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const Validator = Yup.object().shape({
    message: Yup.string()
      .required("Message Required.")
      .max(512, "Message exceeds Character limit.")
      .min(10, "Message too Short.")
      .matches(Regx.textWithSpclChar, "Invalid Message.")
  });

  const handleSubmit = async (data) => {
    await callCreateAPI(data);
  }

  const callCreateAPI = async (inputPayload) => {
    Axios.post(NOTIFICATIONCREATE_URL, inputPayload).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
        navigate(SiteMap.NotificationPage.path);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  return (
    <Layout >
      <div className='aj-main'>
        <Breadcrumbs aria-label="breadcrumb" className='aj-bread-crumbs'>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.DashboardPage.path)}}>
            Dashboard
          </Link>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.NotificationPage.path)}}>
          Notifications
          </Link>
          <Typography color="text.primary">Create New Notification</Typography>
        </Breadcrumbs>

        <Card sx={{ minWidth: 180, }} className="aj-card-main" >
          <div className="aj-card-heading back-primary">
            <h4>Create New Notification</h4>
          </div>
          <div className="aj-card-body" >
            <FormikForm validator={Validator} initialValues={{'user_id': authState.user.id, 'type':'HR-TC'}} onSubmit={handleSubmit}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 0.5, sm:0.5, md: 1, lg: 1, xl:2 }}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormikTextField 
                      label="Message"
                      name="message"
                      className="aj-text-input"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikAutoComplete
                      name="to_user_id"
                      size="small"
                      label="Select Telle Caller"
                      className="aj-text-input"
                      items={tcdata}
                      setSearch={setSearch}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikSelect
                      label="Priority"
                      size="small"
                      name="priority"
                      items={NotificationPriority}
                      defaultValue='Medium'
                      placeholder="Choose Status"
                      />
                      
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikSelect
                      label="Status"
                      size="small"
                      name="status"
                      items={NotificationStatus}
                      defaultValue='New'
                      placeholder="Choose Status"
                      />
                      
                  </Grid>
                </Grid>
                <br />
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" className="aj-card-footer">
                  <Button variant="contained" color="primary" className="aj-btn-bold"  onClick={() => {navigate(SiteMap.NotificationPage.path)}}>
                    Back
                  </Button>
                  <Button variant="contained" type="submit" className='aj-btn-primary' >
                    Create
                  </Button>
                </Stack>
              </Box>
            </FormikForm>
          </div>
        </Card>
      </div>
    </Layout>
  )
}

export default NotificationCreatePage