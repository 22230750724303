import { Breadcrumbs, Button, Card, Fab, FormControl, Grid, InputLabel, Link, MenuItem, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Layout } from '../../components';
import { useNavigate } from 'react-router-dom';
import { SiteMap } from '../../Routes';
import { Add as AddIcon } from '@mui/icons-material';
import { actions } from '../../redux';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Axios, LEAVELIST_URL, LEAVESTATUS_URL } from '../../utils/apiUtils';
import moment from 'moment';
import { Box } from '@mui/system';
import { ALLLimits, LeavesStatus } from '../../utils/constants';
import EditIcon from '@mui/icons-material/Edit';

const LeavesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector(state => state.auth);
  const [lists, setLists] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('All');
  const [limit, setLimit] = useState(10);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callLeadsListAPI();
  }, []);

  const callLeadsListAPI = async (show_page) => {
    if( show_page === undefined || show_page === null) show_page = 1;

    Axios.post(LEAVELIST_URL, {page: show_page, search: search, status: status, limit: limit, hr_id: authState.user.id}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLists(response.data.data);
        setCount( Math.ceil(response.data.count / limit));
        setPage(response.data.page);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const handlePageChange = (event, newPage)=>{
    callLeadsListAPI(newPage);
  }

  const handleChangeStatus = (event, id) => {
    Axios.post(`${LEAVESTATUS_URL}/${id}`, {status: event.target.value}).then((response) => {
      if (response.data.sts === "00") {
        ShowToast({ "message": response.data.msg, "severity": "error" });
      } else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  };
  

  return (
    <Layout >
      <div className='aj-main'>
        <Breadcrumbs aria-label="breadcrumb" className='aj-bread-crumbs'>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.DashboardPage.path)}}>
            Dashboard
          </Link>
          <Typography color="text.primary">Leave Application</Typography>
        </Breadcrumbs>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 0.5, sm:0.5, md: 1, lg: 1, xl:2 }}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <TextField
                label="Search"
                name="search"
                id="hr-search"
                className="aj-text-input"
                size="small"
                fullWidth
                onChange={ (e) => {setSearch(e.target.value)} }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2}>
              <FormControl fullWidth>
                <InputLabel id="hr-limit-label" >Limit</InputLabel>
                <Select
                  labelId="hr-limit-label"
                  id="hr-limit"
                  label="Limit"
                  size="small"
                  name="limit"
                  defaultValue={10}
                  onChange={ (e) => {setLimit(e.target.value)} }
                >
                  {ALLLimits &&
                    ALLLimits.map(option => (
                      <MenuItem id={option.value} key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={1}>
              <Button
                variant="contained"
                type="submit"
                className='aj-btn-primary'
                id="hr-submit"
                onClick={ () => {callLeadsListAPI()} }
                fullWidth
                >
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>
        <br />
      
        <Card >
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size='small' className='aj-resp-table'>
              <TableHead className='aj-table-primary'>
                <TableRow>
                  <TableCell className='text-center'>ID</TableCell>
                  <TableCell className='text-center'>Name</TableCell>
                  <TableCell className='text-center'>Message</TableCell>
                  <TableCell className='text-center'>From Date</TableCell>
                  <TableCell className='text-center'>Days</TableCell>
                  <TableCell className='text-center'>Status</TableCell>
                  <TableCell className='text-center'>Created At</TableCell>
                  <TableCell className='text-center'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { count > 0 ? 
                  lists.map( (hr) => {
                    return(
                      <TableRow key={hr.id} >
                        <TableCell align="center">{hr.id}</TableCell>
                        <TableCell align="center">{hr.name}</TableCell>
                        <TableCell className='td-manage-md'>{hr.message}</TableCell>
                        <TableCell>{ hr.from_date ? moment(hr.from_date).format("YYYY-MM-DD") : '-' }</TableCell>
                        <TableCell>{hr.days}</TableCell>
                        <TableCell >
                          <FormControl fullWidth size="small">
                            <Select
                              name={hr.id ? `status-${hr.id}` : ''}
                              defaultValue={hr.status}
                              onChange={(e)=>{handleChangeStatus(e, hr.id)}}
                            >
                              { LeavesStatus &&  LeavesStatus.map(option => (
                                <MenuItem id={option.value} key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>{ hr.created_at ? moment(hr.created_at).format("YYYY-MM-DD kk:mm:00") : '-' }</TableCell>
                        <TableCell align="center">
                          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                            <Button variant="contained" color="warning" startIcon={<EditIcon />} className="aj-btn-bold" size="medium" onClick={() => {navigate(SiteMap.LeavesPage.path+'/'+hr.id)}}>
                              Edit
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  }) 
                : 
                  <TableRow key={0} >
                    <TableCell colSpan={9} align="center">No Results Found!</TableCell>
                  </TableRow>
                }
              </TableBody>
              {count > 0 ?
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <Pagination count={count} page={page} onChange={handlePageChange} color="primary" shape="rounded" />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              : '' }
            </Table>
          </TableContainer>
        </Card>
      </div>

    </Layout>
  )
}

export default LeavesPage