import { Breadcrumbs, Button, Card, FormControl, InputLabel, Link, Select, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormikTextField, Layout, FormikSelect } from '../../components';
import { useNavigate } from 'react-router-dom';
import { SiteMap } from '../../Routes';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import FormikForm from '../../components/FormikForm/FormikForm';
import * as Yup from "yup";
import { Regx } from '../../utils/Regx';
import { Axios, DISTRICTSEARCH_URL, LEADSCREATE_URL, SERVICESLIST_URL, STATESEARCH_URL, TCSEARCH_URL } from '../../utils/apiUtils';
import { bindActionCreators } from 'redux';
import { actions } from '../../redux';
import { useDispatch, useSelector } from 'react-redux';
import { LeadsStatus } from '../../utils/constants';
import { useState } from 'react';
import FormikMultiSelect from '../../components/FormikMultiSelect/FormikMultiSelect';
import FormikMultiTextField from '../../components/FormikMultiTextField/FormikMultiTextField';
import FormikAutoComplete from '../../components/FormikAutoComplete/FormikAutoComplete';

function LeadsCreatePage() {
  const authState = useSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [services, setServices] = useState([]);
  const [state , setState] = useState([]);
  const [tcdata , setTCData] = useState([]);
  const [search, setSearch] = useState('');
  const [district , setDistrict] = useState([]);
  const [searchDistrict, setSearchDistrict] = useState('');
  const [stateID , setStateID] = useState(0);
  const [distReset , setDistReset] = useState(0);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callServicesListAPI();
    callStateListAPI(search);
    callTCSearchListAPI(search);
    if(searchDistrict) callDistrictListAPI(searchDistrict);
  }, [search, searchDistrict]);

  const callServicesListAPI = async (show_page) => {
    Axios.post(SERVICESLIST_URL, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setServices(response.data.services);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const callStateListAPI =  (search) => {
    Axios.post(STATESEARCH_URL, {search:search}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        setState(response.data.states);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const callTCSearchListAPI =  (search) => {
    Axios.post(TCSEARCH_URL, {search:search, hr_id: authState.user.id}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        setTCData(response.data.states);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const callDistrictListAPI =  (search, stateID) => {
    Axios.post(DISTRICTSEARCH_URL, {search:search, state_id: stateID}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        setDistrict(response.data.districts);
        setDistReset(distReset + 1);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const Validator = Yup.object().shape({
    name: Yup.string()
      .required("User Name Required.")
      .max(60, "User Name exceeds Character limit.")
      .min(3, "User Name too Short.")
      .matches(Regx.plainText, "Invalid User Name."),
    mobile: Yup.string()
      .required("Mobile Number Required.")
      .min(10, '10 digit Mobile Number Required.')
      .max(10, '10 digit Mobile Number Required.'),
    whatsapp: Yup.string()
      .min(10, '10 digit Mobile Number Required.')
      .max(10, '10 digit Mobile Number Required.'),
    company: Yup.string()
      .max(60, "Company Name exceeds Character limit.")
      .min(3, "Company Name too Short.")
      .matches(Regx.plainText, "Invalid Company Name."),
    telle_desc: Yup.string()
      .matches(Regx.textWithSpclChar, "Invalid Description."),
    services: Yup.array()
      .min(1, "Atleast one service required"),
    state_id: Yup.string()
      .matches(Regx.number, "Invalid State."),
    district_id: Yup.string()
      .matches(Regx.number, "Invalid District."),
    address: Yup.string()
      .matches(Regx.textWithSpclChar, "Invalid Address."),
    telle_id: Yup.string()
      .required("Telle Caller Required.")
      .matches(Regx.number, "Invalid Telle Caller."),
  });

  const handleSubmit = async (data) => {
    await callCreateAPI(data);
  }

  const callCreateAPI = async (inputPayload) => {
    Axios.post(LEADSCREATE_URL, inputPayload).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
        navigate(SiteMap.LeadsPage.path);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  React.useEffect(() => {
    callDistrictListAPI(null, stateID)
  }, [stateID])

  return (
    <Layout >
      <div className='aj-main'>
        <Breadcrumbs aria-label="breadcrumb" className='aj-bread-crumbs'>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.DashboardPage.path)}}>
            Dashboard
          </Link>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.LeadsPage.path)}}>
            Leads
          </Link>
          <Typography color="text.primary">Create New Leads</Typography>
        </Breadcrumbs>

        <Card sx={{ minWidth: 180}} className="aj-card-main">
          <div className="aj-card-heading back-primary">
            <h4>Create New Lead</h4>
          </div>
          <div className="aj-card-body">
            <FormikForm validator={Validator} initialValues={{'hr_id': authState.user.id, 'status': 'New'}} onSubmit={handleSubmit}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 0.5, sm:0.5, md: 1, lg: 1, xl:2 }}>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikTextField 
                      label="Lead Name"
                      name="name"
                      className="aj-text-input"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikTextField 
                      label="Mobile Number"
                      name="mobile"
                      className="aj-text-input"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikTextField 
                      label="Whatsapp/Second Number"
                      name="whatsapp"
                      className="aj-text-input"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikTextField 
                      label="Company Name"
                      name="company"
                      className="aj-text-input"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikMultiSelect
                      label="Services"
                      size="small"
                      name="services"
                      items={services}
                      placeholder="Choose Services"
                      className="aj-text-input"
                      />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikAutoComplete
                      name="state_id"
                      size="small"
                      label="Select State"
                      className="aj-text-input"
                      items={state}
                      setSearch={() => {setSearch()}}
                      onChange={(e, newValue) => { if(newValue) setStateID(newValue.id); }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikAutoComplete
                      name="district_id"
                      size="small"
                      label="Select District"
                      className="aj-text-input"
                      items={district}
                      setSearch={() => {setSearchDistrict()}}
                      reset={() => {setDistReset()}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikAutoComplete
                      name="telle_id"
                      size="small"
                      label="Select Telle Caller"
                      className="aj-text-input"
                      items={tcdata}
                      setSearch={setSearch}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikSelect
                      label="Status"
                      size="small"
                      name="status"
                      items={LeadsStatus}
                      placeholder="Choose Status"
                      className="aj-text-input"
                      />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormikMultiTextField
                      label="TelleCaller Description"
                      name="telle_desc"
                      className="aj-text-input"
                      size="small"
                      rows="4"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormikMultiTextField
                      label="Address"
                      name="address"
                      className="aj-text-input"
                      size="small"
                      rows="4"
                    />
                  </Grid>
                </Grid>
                <br />
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" className='aj-card-footer'>
                  <Button variant="contained" color="primary" className="aj-btn-bold"  onClick={() => {navigate(SiteMap.HumanRightsPage.path)}}>
                    Back
                  </Button>
                  <Button variant="contained" type="submit" className='aj-btn-primary' >
                    Create
                  </Button>
                </Stack>
              </Box>
            </FormikForm>
          </div>
        </Card>
      </div>
    </Layout>
  )
}

export default LeadsCreatePage