import { AUTH_CONTEXT } from '../context';

//SIGN IN ACTIONS
export const RegisterLogin = (payload) => ({
    type: AUTH_CONTEXT.LOGIN,
    data: payload
})

//LOGOUT ACTIONS
export const RegisterLogout = () => ({
    type: AUTH_CONTEXT.LOGOUT
})
