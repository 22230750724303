import React from 'react';
import RouteItems from './Routes';
import Toast from "./components/Toast/Toast";
import { useSelector } from "react-redux";
import './assets/css/styles.css';

function App() {
  const ToastState = useSelector((state) => state.toast);
  
  return (
    <React.Fragment>
      <Toast
				open={ToastState.open}
				message={ToastState.message}
				severity={ToastState?.severity}
			/>
      <RouteItems/>
    </React.Fragment>
  );
}

export default App;
