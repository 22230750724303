import { Breadcrumbs, Button, Card, Divider, Fab, FormControl, Grid, InputLabel, Link, MenuItem, Modal, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Layout } from '../../components';
import { useNavigate } from 'react-router-dom';
import { SiteMap } from '../../Routes';
import { Add as AddIcon } from '@mui/icons-material';
import { actions } from '../../redux';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Axios, TCLIST_URL, TCDELETE_URL, TCCHANGE_URL, TCDSTATUS_URL } from '../../utils/apiUtils';
import moment from 'moment';
import { Box } from '@mui/system';
import { ALLLimits, TCStatus } from '../../utils/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import NearMeIcon from '@mui/icons-material/NearMe';

const TelleCallersPage = () => {
  const authState = useSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tcLists, setTCLists] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('All');
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [openChange, setOpenChange] = React.useState(false);
  const [tcId, setTCId] = useState(0);
  const [tcName, setTCName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callTCListAPI();
  }, []);

  const callTCListAPI = async (show_page) => {
    if( show_page === undefined || show_page === null) show_page = 1;

    Axios.post(TCLIST_URL, {page: show_page, search: search, status: status, limit: limit, hr_id:authState.user.id}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setTCLists(response.data.users);
        setCount( Math.ceil(response.data.count / limit));
        setPage(response.data.page);
      }
    }).catch( err=> {
    console.log("err res", err.response);
    if (err.response && err.response.data && err.response.data.msg )
      ShowToast({ "message": err.response.data.msg, "severity": "error" });
    else
      ShowToast({ "message": err.message, "severity": "error" });
  });
  }

  const handlePageChange = (event, newPage)=>{
    callTCListAPI(newPage);
  }

  const handleDeleteModel = (tcId, tcName) => {
    setTCId(tcId);
    setTCName(tcName);
    setOpen(true);
  }

  const handleDelete = () => {
    callTCDeleteAPI()
  }

  const handleChangeModel = (tcId, tcName) => {
    setTCId(tcId);
    setTCName(tcName);
    setOpenChange(true);
  }

  const handlePassword = () => {
    if(password.length === 0)
      setPasswordError('Password Required.');
    else if(password.length < 6)
      setPasswordError('6 Digit Password Required.');
    else {
      setPasswordError('');
      callTCChangeAPI();
    }
  }

  const handlePasswordInput = (e) => {
    setPassword(e.target.value);
    if(e.target.value.length === 0)
      setPasswordError('Password Required.');
    else if(e.target.value.length < 6)
      setPasswordError('6 Digit Password Required.');
    else
      setPasswordError('');
  }

  const callTCDeleteAPI = async () => {
    Axios.post(`${TCDELETE_URL}/${tcId}`).then((response) => {
      setOpen(false);
      if (response.data.sts === "00") {
        ShowToast({ "message": response.data.msg, "severity": "error" });
      } else {
        callTCListAPI(1);
        ShowToast({ "message": response.data.msg, "severity": "success" });
      }
    }).catch( err=> {
    console.log("err res", err.response);
    if (err.response && err.response.data && err.response.data.msg )
      ShowToast({ "message": err.response.data.msg, "severity": "error" });
    else
      ShowToast({ "message": err.message, "severity": "error" });
  });
  }

  const callTCChangeAPI = async () => {
    Axios.post(`${TCCHANGE_URL}/${tcId}`, {password: password}).then((response) => {
      setOpenChange(false);
      if (response.data.sts === "00") {
        ShowToast({ "message": response.data.msg, "severity": "error" });
      } else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
      }
    }).catch( err=> {
    console.log("err res", err.response);
    if (err.response && err.response.data && err.response.data.msg )
      ShowToast({ "message": err.response.data.msg, "severity": "error" });
    else
      ShowToast({ "message": err.message, "severity": "error" });
  });
  }

  const handleChangeStatus = (event, tcId) => {
    Axios.post(`${TCDSTATUS_URL}/${tcId}`, {status: event.target.value}).then((response) => {
      if (response.data.sts === "00") {
        ShowToast({ "message": response.data.msg, "severity": "error" });
      } else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
      }
    }).catch( err=> {
    console.log("err res", err.response);
    if (err.response && err.response.data && err.response.data.msg )
      ShowToast({ "message": err.response.data.msg, "severity": "error" });
    else
      ShowToast({ "message": err.message, "severity": "error" });
  });
  };

  return (
    <Layout >
      <div className='aj-main'>
        <Breadcrumbs aria-label="breadcrumb" className='aj-bread-crumbs'>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.DashboardPage.path)}}>
            Dashboard
          </Link>
          <Typography color="text.primary">TeleCallers</Typography>
        </Breadcrumbs>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 0.5, sm:0.5, md: 1, lg: 1, xl:2 }}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <TextField
                label="Search"
                name="search"
                id="tc-search"
                className="aj-text-input"
                size="small"
                fullWidth
                onChange={ (e) => {setSearch(e.target.value)} }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <FormControl fullWidth>
                <InputLabel id="tc-status-label" >Status</InputLabel>
                <Select
                  labelId="tc-status-label"
                  id="tc-status"
                  label="Status"
                  size="small"
                  name="status"
                  defaultValue="All"
                  onChange={ (e) => {setStatus(e.target.value)} }
                  className="aj-text-input"
                >
                  <MenuItem id="all" key="all" value="All">Choose Status</MenuItem>
                  { TCStatus && TCStatus.map(option => (
                    <MenuItem id={option.value} key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <FormControl fullWidth>
                <InputLabel id="tc-limit-label" >Limit</InputLabel>
                <Select
                  labelId="tc-limit-label"
                  id="tc-limit"
                  label="Limit"
                  size="small"
                  name="limit"
                  defaultValue={10}
                  onChange={ (e) => {setLimit(e.target.value)} }
                  className="aj-text-input"
                >
                  {ALLLimits &&
                    ALLLimits.map(option => (
                      <MenuItem id={option.value} key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={1}>
              <Button
                variant="contained"
                type="submit"
                className='aj-btn-primary'
                id="tc-submit"
                onClick={ () => {callTCListAPI()} }
                fullWidth
                >
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>
        <br />
      
        <Card >
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size='small' className='aj-resp-table'>
              <TableHead className='aj-table-primary'>
                <TableRow>
                  <TableCell className='text-center'>ID</TableCell>
                  <TableCell className='text-center'>Name</TableCell>
                  <TableCell className='text-center'>Mobile</TableCell>
                  <TableCell className='text-center'>Email</TableCell>
                  <TableCell className='text-center'>Monthly Target</TableCell>
                  <TableCell className='text-center'>Daily Target</TableCell>
                  <TableCell className='text-center'>Created At</TableCell>
                  <TableCell className='text-center'>Status</TableCell>
                  <TableCell className='text-center'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { count > 0 ? 
                  tcLists.map( (tc) => 
                    <TableRow key={tc.id} >
                      <TableCell align="center">{tc.id}</TableCell>
                      <TableCell>
                      <Link href={ tc.id ? `https://telecallers.seclob.in/admin/${btoa(tc.id)}` : ''} target="_blank" underline="none" sx={{ color: 'info.main',fontWeight: 600 }}>{tc.name}</Link>
                      </TableCell>
                      <TableCell>{tc.mobile}</TableCell>
                      <TableCell>{tc.email}</TableCell>
                      <TableCell>{tc.monthly_tar_amount}</TableCell>
                      <TableCell>{tc.daily_tar_leads}</TableCell>
                      <TableCell>{ tc.created_at ? moment(tc.created_at).format("YYYY-MM-DD kk:mm:00") : '-' }</TableCell>
                      <TableCell >
                        <FormControl fullWidth size="small">
                          <Select
                            name={tc.id ? `status-${tc.id}` : ''}
                            defaultValue={tc.status}
                            onChange={(e)=>{handleChangeStatus(e, tc.id)}}
                          >
                            { TCStatus &&  TCStatus.map(option => (
                              <MenuItem id={option.value} key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                          <Button variant="contained" color="info" startIcon={<LockIcon />} className="aj-btn-bold" size="medium" onClick={() => handleChangeModel(tc.id, tc.name)}>
                            Password
                          </Button>
                          <Button variant="contained" color="warning" startIcon={<EditIcon />} className="aj-btn-bold" size="medium" onClick={() => {navigate(SiteMap.TelleCallersPage.path+'/'+tc.id)}}>
                            Edit
                          </Button>
                          <Button variant="contained" color="error" startIcon={<DeleteIcon />} className="aj-btn-bold" size="medium" onClick={() => handleDeleteModel(tc.id, tc.name)}>
                            Delete
                          </Button>
                          <Button variant="contained" color="info" startIcon={<NearMeIcon />} className="aj-btn-bold" size="medium" onClick={() => {navigate(SiteMap.TelleCallersPage.path+'/location/'+tc.id)}}>
                            Location
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ) 
                : 
                  <TableRow key={0} >
                    <TableCell colSpan={7} align="center">No Results Found!</TableCell>
                  </TableRow>
                }
              </TableBody>
              {count > 0 ?
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Pagination count={count} page={page} onChange={handlePageChange} color="primary" shape="rounded" />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              : '' }
            </Table>
          </TableContainer>
        </Card>
      </div>
        
      <Fab color="secondary" aria-label="add" className='aj-add-icon'  onClick={() => {navigate(SiteMap.TelleCallersCreatePage.path)}}>
        <AddIcon />
      </Fab>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="aj-model-box">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete TeleCaller: {tcName ? tcName : ''}
          </Typography>
          <Divider />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure do you really want to Delete this Member?
          </Typography>
          <br />
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" className='aj-card-footer'>
            <Button variant="contained" color="primary" className="aj-btn-bold" size="small" onClick={() => setOpen(false)}>
              Close
            </Button>
            <Button variant="contained" color="error" className="aj-btn-bold" size="small" onClick={ () => handleDelete()}>
              Delete
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openChange}
        onClose={() => setOpenChange(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="aj-model-box">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Change Password: {tcName ? tcName : ''}
          </Typography>
          <Divider />
          <Stack spacing={2} mt={3} >
            { passwordError ?
              <TextField 
                label="New Password"
                name="Password"
                variant="outlined"
                size="small"
                onChange={handlePasswordInput}
                helperText={passwordError}
                error
              />
            :
              <TextField 
                label="New Password"
                name="Password"
                variant="outlined"
                size="small"
                onChange={handlePasswordInput}
                helperText=""
              />
            }
          </Stack>
          <br />
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" className='aj-card-footer'>
            <Button variant="contained" color="info" className="aj-btn-bold" size="small" onClick={() => setOpenChange(false)}>
              Close
            </Button>
            <Button variant="contained" color="primary" className="aj-btn-bold" size="small" onClick={ () => handlePassword()}>
              Change
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Layout>
  )
}

export default TelleCallersPage